<template>
  <v-container fluid>
    <v-snackbar top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>
    <v-overlay class="loading-center" :value="saving">
      <lottie
        :options="defaultOptions"
   :height="150"
        :width="150"
        v-on:animCreated="handleAnimation"
      />
    </v-overlay>

    <v-row class="pa-0 ma-0">
      <v-col cols="12" sm="11" md="11">
        <h1>Usuarios Cuponera Corporativa</h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="12" sm="12">
        <v-row justify="end">
          <v-btn
            color="primary"
            class="ma-2 white--text"
            @click="modalAddUser = true"
            :loading="loading"
          >
            <v-icon right dark class="mr-3">fas fa-plus</v-icon>
            Crear nuevo usuario
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="pa-0 ma-0">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="users"
          :search="$store.state.search"
          :items-per-page="15"
          :loading="loading"
          :sort-by="['name']"
          :sort-asc="true"
          item-key=".key"
          height="75vh"
          fixed-header
          :footer-props="{
            itemsPerPageOptions: [10, 25, 50, 100, 250],
          }"
        >
          <template v-slot:loading>
            <div class="ma-5" style="position: relative">
              <div
                style="
                  position: absolute;
                  z-index: 999;
                  width: 100%;
                  height: 100%;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                "
              >
                <lottie
                  :options="defaultOptions"
                  :height="300"
                  :width="400"
                  v-on:animCreated="handleAnimation"
                />
                <p
                  :class="
                    $vuetify.theme.dark
                      ? 'subtitle-1 primary--text'
                      : 'subtitle-1 black--text'
                  "
                >
                  Cargando Data
                </p>
              </div>
              <v-skeleton-loader
                ref="skeleton"
                type="table-tbody"
                class="mx-auto d-none d-md-block"
              ></v-skeleton-loader>
            </div>
          </template>

          <template v-slot:[`item.businessID`]="{ item }">
            <div v-if="item.business">
              {{ item.business.shortName }}
            </div>
          </template>

          <template v-slot:[`item.imagen`]="{ item }">
            <v-avatar>
              <v-img
                v-if="item.business && item.business.logo"
                :src="item.business.logo.original"
                :lazy-src="item.business.logo.loading"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-avatar>
          </template>

          <template v-slot:[`item.active`]="{ item }">
            <v-switch
              @change="
                switchControlChanged(
                  { active: item.active },
                  item['.key'],
                  item
                )
              "
              v-model="item.active"
            ></v-switch>
          </template>

          <template v-slot:[`item.email`]="{ item }">
            <v-tooltip v-if="item.email" left>
              <template v-slot:activator="{ on }">
                <v-chip
                  :color="
                    $vuetify.theme.dark
                      ? 'rgba(0, 0, 0, 0.4)'
                      : 'rgba(0, 0, 0, 0.1)'
                  "
                  style="display: inline-block"
                  v-on="on"
                  dark
                  :text-color="$vuetify.theme.dark ? 'white' : 'black'"
                  @click="copyToClipboard(item.email)"
                  >{{ item.email }}</v-chip
                >
              </template>
              <i class="far fa-copy mr-2"></i>
              <span>Click para copiar</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.options`]="{ item }">
            <v-btn @click="editUser(item)" small color="primary" class="mr-3"
              >Editar usuario</v-btn
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog v-if="userSelected" v-model="modalEditUser" max-width="800px">
      <edit-user
        :businesses="businesses"
        @cancel="modalEditUser = false"
        @notFound="userNotFound()"
        @success="updateSuccess"
        :key="userSelected['.key']"
        :user="userSelected"
      ></edit-user>
    </v-dialog>

    <v-dialog v-model="modalAddUser" max-width="800px">
      <add-user
        type="corporateCoupons"
        @cancel="modalAddUser = false"
        @success="userCreated"
        :businesses="businesses"
      ></add-user>
    </v-dialog>
  </v-container>
</template>

<script>
import { db, fb } from "@/firebase";
import Lottie from "../../../components/Lottie";
import * as animationData from "../../../assets/ochoColor.json";
import { Parser } from "json2csv";

import editBusinesses from "./edit-businessOwner-businesses";
import editUser from "./edit-user";
import addUser from "./create-user";
import { mapState } from "vuex";

export default {
  name: "users-cargo",
  components: {
    lottie: Lottie,
    editBusinesses,
    editUser,
    addUser,
  },
  data() {
    return {
      loading: true,
      saving: false,
      snackbar: false,
      modalEditBusinesses: false,
      userSelected: null,
      snackbarText: "",
      timer: null,
      itemToEdit: {},
      users: [],
      businesses: [],
      defaultOptions: {
        animationData: animationData.default,
        loop: true,
        autoplay: true,
      },
      modalAddUser: false,
      modalEditUser: false,

      anim: null,
      animationSpeed: 1.1,
      headers: [
        {
          value: "imagen",
        },
        {
          text: "Nombre",
          value: "name",
        },
        {
          text: "Comercio",
          value: "businessID",
        },
        {
          text: "Correo",
          value: "email",
        },
        {
          text: "Activo",
          value: "active",
        },
        {
          value: "options",
          width: "200px",
          align: "end",
        },
      ],
    };
  },
  computed: {
    ...mapState(["user"]),
    generateColor() {
      var letters = "0123456789ABCDEF".split("");
      var color = "#";
      for (var i = 0; i < 6; i++) {
        color += letters[Math.round(Math.random() * 10)];
      }
      return color;
    },
  },
  watch: {
    users() {
      this.users.forEach((user) => {
        user.active = user.active == undefined ? true : user.active;
        if (user.businessID) {
          user.business = this.businesses.find(
            (item) => item[".key"] == user.businessID
          );
        }
      });
      this.loading = false;
    },
  },
  methods: {
    firstLetter(name) {
      return name != undefined ? name.split("")[0] : "";
    },

    userNotFound() {
      this.snackbarText = "Usuario no encontrado.";
      this.snackbar = true;
      this.modalEditUser = false;
      this.userSelected = null;
    },

    handleAnimation(anim) {
      this.anim = anim;
      anim.setSpeed(this.animationSpeed);
    },

    editUser(item) {
      this.userSelected = item;
      this.modalEditUser = true;
    },

    userCreated() {
      // this.getUsers();
      this.modalAddUser = false;
      this.snackbar = true;
      this.snackbarText = "Usuario agregado exitosamente.";
    },

    editBusinesses(item) {
      this.userSelected = item;
      this.modalEditBusinesses = true;
    },

    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(
        () => {
          this.snackbar = true;
          this.snackbarText = "Copiado al portapapeles";
        },
        (err) => {
          console.error("Async: Could not copy text: ", err);
        }
      );
    },

    updateSuccess() {
      this.modalEditBusinesses = false;
      this.modalEditUser = false;
      this.snackbar = true;
      this.snackbarText = "Información del usuario actualizada correctamente.";
    },

    switchControlChanged(data, userId, user) {
      if (data && userId) {
        this.saving = true;
        db.collection("appUsers")
          .doc(userId)
          .update({
            active: data.active,
            modifiedAt: new Date(),
            modifiedBy: this.user[".key"],
          })
          .then((ref) => {
            this.saving = false;
            this.snackbarText = "Información actualizada correctamente.";
            this.snackbar = true;
          })
          .catch((err) => {
            this.saving = false;
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;

            user.active = !user.active;
          });
      }
    },
  },

  async mounted() {
    this.$store.commit("setSearchTerm", "usuario");
    this.$store.commit("setSearch", "");
    this.$store.state.visibleSearch = true;

    await this.$binding("businesses", db.collection("corporateCoupons"));

    await this.$binding(
      "users",
      db.collection("appUsers").where("type", "==", "corporateCoupons")
    );
  },
};
</script>